/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  TileFormWrapper,
  TileFormSpan,
  TileFormSection,
  TileFormSectionTitle,
  TileFormRowWithData, LinkWithIconCheckOnMap, MapTile, TitleFormSectionSubTitle, IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { formatNumber, getAxios } from '@dataplace.ai/functions/utils'
import styled, { css } from 'styled-components'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { MainDropdown, MapVisualizationModal, ComparedLocationBean, AddMapView } from '@dataplace.ai/ui-components/molecules'
import { ILocation, IRange } from '@dataplace.ai/types'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { subDays } from 'date-fns'
import { minTrafficDate } from 'apps/placeme/src/features/Analyse/utils/constants'
import { customerOriginTableData } from './data'
import { ICustomerOriginTileData } from './@types/ICustomerOriginTileData'
import { DateRangeSelector } from '../../../../molecules'
import { ComparedLocationHeader, TileFooter } from '../../../../atoms'
import { compareLocationCatchmentAndDataAction, saveNewRangeAction, saveTileData } from '../../../../../slice/analysisSlice'
import { RootState } from '../../../../../../../redux/store'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { SettingsBox } from '../../../../molecules/SettingsBox/SettingsBox'

const { v4: uuidv4 } = require('uuid')

const RoundedSpan = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    background-color: ${palette.product.location.medium};
    color: ${palette.light.white};
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    >p{
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
      text-align: center;
      }
  `
})

const StyledTileFormWrapper = styled(TileFormWrapper)<{background: 'light' | 'dark'}>(({
  theme, background,
}) => {
  const { palette } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
  `
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  () => css`
      margin-bottom: 1rem;
    `,
)

const LegendWrapper = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme

  return css`
    display: flex;
    margin-top: 1rem;
    width:100%;

    >div{
      display: flex;
      align-items: center;

      >p{
        margin-left: 1.5rem;
        color: ${palette.black};
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
      }

      >span{
            width: 60px;
            height: 60px;
            border: 1px solid;
      }

      :first-of-type{
        >span{
            border-color: #70C43B;
            background-color: rgba(112, 196, 59, 0.2);
          }
      }

      :last-of-type{
        margin-left: 0.5rem;
        >span{
            border-color: #F08F7F;
            background-color: rgba(240, 143, 127, 0.2);
          }
      }
    }
  `
})

const mapTypes = ['destination_road', 'area']

export const CustomerOriginTile: React.FC<{data: ICustomerOriginTileData, isExtraPaid?: boolean, tileId: string}> = ({
  data, isExtraPaid, tileId,
}) => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const dispatch = useAppDispatch()
  const { labels } = customerOriginTableData

  const {
    creditsAmount, values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const {
    currentSubscriptionData, value, analyseId,
  } = useSelector((state: RootState) => state.location)

  const [dateRanges, setDateRanges] = useState<{startDate?: Date, endDate?: Date}>({})
  const [accepted, setAccepted] = useState<boolean>(false)
  const [token, setToken] = useState('')
  const [mapType, setMapType] = useState<string>('destination_road')
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [comparedMapType, setComparedMapType] = useState<string>('destination_road')
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [isMapVisible, setIsMapVisible] = useState(false)
  const [isComparedMapVisible, setIsComparedMapVisible] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  const catchmentId = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  // functions
  const handleSubmit = () => {
    if (creditsAmount) {
      const chosenRange = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
        t.id === tileId)?.chosenRange

      if (token && !chosenRange?.catchmentId) {
        dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'traffic', tileId, {
          id: `${tileId}-250-line`,
          value: 250,
          type: 'line',
        }))
      }
      analytics?.track('Tile Data Generated', {
        tile: tileId?.split('-')[0],
        range: {
          type: chosenRange?.type,
          length: chosenRange?.value,
        },
      })
    }
  }

  const fetchData = useCallback(async () => {
    if (accepted && catchmentId) {
      const body = {
        catchmentId,
        dataRanges: {
          startDate: dateRanges.startDate?.getTime(),
          endDate: dateRanges.endDate?.getTime(),
        },
      }

      let saveData
      const axiosInstance = await getAxios({
        errCallbackFn: (e) => {
          saveData = {
            loading: false,
            error: e,
            value: null,
          }
        },
      })
      const response = await axiosInstance.post<ITileData>(ENDPOINTS.CUSTOMER_ORIGIN_TILE, body)
      if (response) {
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      }
      dispatch(saveTileData('traffic', tileId, saveData))
    }
  }, [accepted, token, catchmentId])

  const handleMapSelect = (id: string) => {
    setMapType(id)
  }
  const handleComparedMapSelect = (id: string) => {
    setComparedMapType(id)
  }

  const handleAddMap = (compared: boolean) => {
    if (compared) setIsComparedMapVisible(true)
    else { setIsMapVisible(true) }
  }

  const handleFetchComparedData = useCallback(async () => {
    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
    try {
      // post project - with compared location - create compared analyse
      if (!comparedAnalyseId) await dispatch(createNewAnalyse(analyseId, comparedLocation?.location))
    }
    finally {
      const customerOriginTile = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
        t.id === tileId)
      dispatch(compareLocationCatchmentAndDataAction(
        token,
        catchmentId || '',
        customerOriginTile?.chosenRange as IRange,
        customerOriginTile?.section || '',
        customerOriginTile?.id || '',
        currentSubscriptionData?.value?.subscriptionId || '',
        comparedLocation?.location as ILocation,
        {
          dataRanges: {
            startDate: dateRanges.startDate?.getTime(),
            endDate: dateRanges.endDate?.getTime(),
          },
        },
      ))
    }
  }, [fetchData, catchmentId, !data?.value, accepted])

  const handleLayer = (compared: boolean) => {
    if (data?.value) {
      const items = compared ? data?.value?.comparedLocation : data?.value
      const typeOfMap = compared ? comparedMapType : mapType
      const features : IFeatureCollection['data']['features'] = []
      if (typeOfMap === 'destination_road') {
        items?.sources?.roadLayerCustomOrigin?.data?.features?.forEach(feat => {
          features.push({
            geometry: feat?.geometry,
            properties: {
              style: {
                color: '#348700',
              },
              width: '1px',
            },
            type: 'Feature',
          })
        })
      }
      else {
        items?.sources?.areaCustomOrigin?.data?.features?.forEach(feat => {
          features.push({
            geometry: feat?.geometry,
            properties:{
              style: {
                ...feat?.properties?.style,
                weight: 0.4,
              },
            },
            type: 'Feature',
          })
        })
      }
      items?.statistics?.top5?.forEach(point =>
      {
        features.push({
          geometry: {
            coordinates: [point?.lng, point?.lat],
            type: 'Point',
          },
          properties:{
            title: `<div style="max-width:450px; display: flex; flex-direction: column; flex-wrap: wrap;"><span>${point?.address}</span><span>${`${t('placeme.customer_origin_tile.section_title_1')}: ${formatNumber(point?.distance)} m`}</span></div>`,
            pinnedItem: {
              iconAnchor:[10, 10],
              class: 'poi-img',
              html: `<span style="display: flex; width: 1.25rem; height: 1.25rem; border-radius: 50%; color: white; background-color: #7E7AD2; align-items: center; justify-content: center;"><p style="text-align: center;">${point?.pointId}</p></span>`,
            },
          },
          type: 'Feature',
        })
      })
      return [{
        id: 'dest_layer',
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'destination_road',
          },
        },
      }]
    }
    return undefined
  }

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  // compared location or fetch basic data (fetchData)
  useEffect(() => {
    if (catchmentId && !data?.value && accepted) {
      const customerOriginTile = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
        t.id === tileId)
      // compared location comparison trigger
      if (comparedLocation?.generatedFromNow && customerOriginTile?.chosenRange?.type !== 'custom') {
        const customerOriginTile = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
          t.id === tileId)
        const catchmentId = customerOriginTile?.chosenRange?.catchmentId

        // compared location
        if (customerOriginTile
            && catchmentId
            && customerOriginTile?.chosenRange?.type !== 'custom'
            && comparedLocation?.location) {
          handleFetchComparedData()
        }
      } else {
        fetchData()
      }
    }
  }, [fetchData, catchmentId, !data?.value, accepted])

  useEffect(() => {
    if (catchmentId) {
      setAccepted(true)
    }
  }, [catchmentId])

  useEffect(() => {
    if (data?.value && mapLocation) {
      dispatch(saveTileData('traffic', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  useEffect(() => {
    if (!isPrinting) return
    handleAddMap(false)
    handleAddMap(true)
  }, [isPrinting])

  return (
    <StyledTileFormWrapper background={data?.value ? 'light' : 'dark'}>

      {(!data?.value && !accepted) && (
        <DateRangeSelector
          accepted={accepted}
          description={t('placeme.customer_origin_tile.date_range_description')}
          maxDate={subDays(new Date(), 3)}
          min={14}
          minDate={minTrafficDate}
          onChange={(startDate, endDate) => setDateRanges({
            startDate,
            endDate,
          })}
          setAccepted={setAccepted}
          title={t('placeme.customer_origin_tile.date_range_title')}
          value={dateRanges}
        />
      )}
      {(!data?.value && !accepted) && (
        <TileFooter
          disabled={!dateRanges?.startDate || !dateRanges?.endDate}
          isExtraPaid={isExtraPaid}
          isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
          label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
          onAccept={handleSubmit}
          onCancel={() => {
            const chosenRange = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
              t.id === tileId)?.chosenRange
            analytics?.track('Tile Cancel Button Clicked', {
              tile: tileId?.split('-')[0],
              range: {
                type: chosenRange?.type,
                value: chosenRange?.value,
              },
            })
          }}
          tile='customer_origin'
          value={checkComparedCoinsValue(
            comparedLocation?.generatedFromNow,
            !!comparedLocation?.location,
            true,
          ).toString()}
        />
      )}
      {(accepted || data?.value) && (
        <>
          {(!data || data?.loading)
            ? <Loader />
            : (
              <>
                <TileFormSection>
                  <SettingsBox
                    noEdit
                    sectionTile='traffic'
                    tile={tileId}
                    typeRanges={{
                      date: {
                        startDate: data?.value?.dataRanges?.startDate,
                        endDate: data?.value?.dataRanges?.endDate,
                      },
                    }}
                  />
                  <TileFormSectionTitle>{t('placeme.customer_origin_tile.section_title_1')}</TileFormSectionTitle>
                  <TileFormRowWithData>
                    <span>{t('placeme.customer_origin_tile.row_with_data_1_span_1')}</span>
                    <span>
                      {formatNumber(data?.value?.statistics?.averageDistance)}
                      {' '}
                      km
                    </span>
                    {data?.value?.comparedLocation?.statistics?.averageDistance && (
                      <ComparedLocationBean
                        comparedAddress={comparedLocation?.location?.address}
                        comparedValue={formatNumber(data?.value?.comparedLocation?.statistics?.averageDistance)}
                        difference={data?.value?.comparedLocation?.statistics?.averageDistanceDiff}
                        mainAddress={value?.address}
                        mainValue={formatNumber(data?.value?.statistics?.averageDistance)}
                      />
                    )}
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.customer_origin_tile.form_span_1')}
                  </TileFormSpan>
                  <TileFormRowWithData>
                    <span>{t('placeme.customer_origin_tile.row_with_data_1_span_2')}</span>
                    <span>
                      {formatNumber(data?.value?.statistics?.maxDistance)}
                      {' '}
                      km
                    </span>
                    {data?.value?.comparedLocation?.statistics?.maxDistance && (
                      <ComparedLocationBean
                        comparedAddress={comparedLocation?.location?.address}
                        comparedValue={formatNumber(data?.value?.comparedLocation?.statistics?.maxDistance)}
                        difference={data?.value?.comparedLocation?.statistics?.maxDistanceDiff}
                        mainAddress={value?.address}
                        mainValue={formatNumber(data?.value?.statistics?.maxDistance)}
                      />
                    )}
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.customer_origin_tile.form_span_2')}
                  </TileFormSpan>
                </TileFormSection>

                <TileFormSection>
                  <TileFormSectionTitle>{t('placeme.customer_origin_tile.section_title_2')}</TileFormSectionTitle>
                  <TileFormSpan
                    style={{
                      marginBottom: '1rem',
                    }}
                  >
                    {t('placeme.customer_origin_tile.form_span_3')}
                  </TileFormSpan>
                  {data?.value?.comparedLocation
                    && (
                      <ComparedLocationHeader>
                        <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                        {' '}
                        <span>{value?.address}</span>
                      </ComparedLocationHeader>
                    )}
                  <Table
                    content={data?.value?.statistics?.top5.map((value) =>
                      [<RoundedSpan key={value.pointId}><p>{value.pointId}</p></RoundedSpan>,
                        <span key={value.address}>{value.address}</span>,
                        <span
                          key={value.distance}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {formatNumber(value.distance)}
                        </span>,
                      ])}
                    customer
                    gap='1rem'
                    headerTemplate='1fr 5fr 2fr'
                    headerTextAlign='left'
                    labels={labels.map((label) => (
                      <span key={uuidv4()}>{t(label)}</span>
                    ))}
                    rowTemplate='1fr 5fr 2fr'
                  />
                </TileFormSection>
                {(!isMapVisible) && (
                  <AddMapView
                    buttonValue={0}
                    description='placeme.add_traffic_visualisation_map.description'
                    onChange={() => { handleAddMap(false) }}
                    title='placeme.add_customer_origin_map.title'
                  />
                )}
                { isMapVisible && (
                  <>
                    <StyledTitleFormSectionSubTitle>
                      <span>{t('placeme.customer_origin.map_title')}</span>
                      <MainDropdown
                        content={(
                          <>
                            {mapTypes.map((s) => (
                              <button
                                key={s}
                                onClick={() => handleMapSelect(s)}
                                style={{
                                  textTransform: 'none',
                                }}
                                type='button'
                              >
                                {t(`placeme.map_type.${s}`)}
                              </button>
                            ))}
                          </>
                        )}
                        header={(
                          <p>
                            {t('placeme.map_types')}
                            :
                            {mapType && <span>{t(`placeme.map_type.${mapType}`)}</span>}
                          </p>
                        )}
                        style={{
                          position: 'relative',
                          zIndex: 450,
                        }}
                      />
                      <LinkWithIconCheckOnMap onClick={() => setIsMapDisplayed(!isMapDisplayed)} />
                    </StyledTitleFormSectionSubTitle>
                    <MapTile
                      dragging
                      height='500px'
                      layers={handleLayer(false)}
                      location={value}
                      mapId='example-map-data-tile'
                      pinDisplayed
                      popupHeading={`${t('generic.chosen_location')}:`}
                      setMapLocation={setMapLocation}
                      showScaleControl
                      width='100%'
                      zoom={15}
                      zoomControl
                    />
                    {mapType === 'area' && (
                      <LegendWrapper>
                        <div>
                          <span />
                          <p>{t('placeme.customer.legend_1.description')}</p>
                        </div>
                        <div>
                          <span />
                          <p>{t('placeme.customer.legend_2.description')}</p>
                        </div>
                      </LegendWrapper>
                    )}
                  </>
                )}
                <TileFormSection />
              </>
            )}
          {isMapDisplayed && (
            <MapVisualizationModal
              isDisplayed={isMapDisplayed}
              layers={handleLayer(false)}
              location={value}
              mapId={`customer-map-${values?.find(c => c.id === 'traffic')?.tiles?.find(t => t.id === 'customer_origin')?.chosenRange?.catchmentId}`}
              setIsDisplay={setIsMapDisplayed}
              zoom={15}
            />
          )}
          {data?.value?.comparedLocation
          && (
            <>

              <ComparedLocationHeader second>
                <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                {' '}
                <span>{comparedLocation?.location?.address}</span>
              </ComparedLocationHeader>
              <Table
                content={data?.value?.comparedLocation?.statistics?.top5.map((value) =>
                  [<RoundedSpan key={value.pointId}><p>{value.pointId}</p></RoundedSpan>,
                    <span key={value.address}>{value.address}</span>,
                    <span
                      key={value.distance}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {formatNumber(value.distance)}
                    </span>,
                  ])}
                customer
                gap='1rem'
                headerTemplate='1fr 5fr 2fr'
                headerTextAlign='left'
                labels={labels.map((label) => (
                  <span key={uuidv4()}>{t(label)}</span>
                ))}
                rowTemplate='1fr 5fr 2fr'
              />

              {(!isComparedMapVisible) && (
                <AddMapView
                  buttonValue={0}
                  description='placeme.add_traffic_visualisation_map.description'
                  onChange={() => handleAddMap(true)}
                  title='placeme.add_customer_origin_map.title'
                />
              )}
              {isComparedMapVisible && (
                <>
                  <StyledTitleFormSectionSubTitle>
                    <span>{t('placeme.customer_origin.map_title')}</span>
                    <MainDropdown
                      content={(
                        <>
                          {mapTypes.map((s) => (
                            <button
                              key={s}
                              onClick={() => handleComparedMapSelect(s)}
                              style={{
                                textTransform: 'none',
                              }}
                              type='button'
                            >
                              {t(`placeme.map_type.${s}`)}
                            </button>
                          ))}
                        </>
                      )}
                      header={(
                        <p>
                          {t('placeme.map_types')}
                          :
                          {comparedMapType && <span>{t(`placeme.map_type.${comparedMapType}`)}</span>}
                        </p>
                      )}
                      style={{
                        position: 'relative',
                        zIndex: 450,
                      }}
                    />
                    <LinkWithIconCheckOnMap onClick={() => setIsComparedMapDisplayed(!isComparedMapDisplayed)} />
                  </StyledTitleFormSectionSubTitle>
                  <MapTile
                    dragging
                    height='500px'
                    layers={handleLayer(true)}
                    location={comparedLocation?.location}
                    mapId='example-map-data-tile'
                    pinDisplayed
                    popupHeading={`${t('generic.chosen_location')}:`}
                    setMapLocation={setComparedMapLocation}
                    showScaleControl
                    width='100%'
                    zoom={15}
                    zoomControl
                  />
                  {comparedMapType === 'area' && (
                    <LegendWrapper>
                      <div>
                        <span />
                        <p>{t('placeme.customer.legend_1.description')}</p>
                      </div>
                      <div>
                        <span />
                        <p>{t('placeme.customer.legend_2.description')}</p>
                      </div>
                    </LegendWrapper>
                  )}
                </>
              )}

              {isComparedMapDisplayed && (
                <MapVisualizationModal
                  isDisplayed={isComparedMapDisplayed}
                  layers={handleLayer(true)}
                  location={comparedLocation?.location}
                  mapId={`customer-map-${values?.find(c => c.id === 'traffic')?.tiles?.find(t => t.id === 'customer_origin')?.chosenRange?.catchmentId}_compared`}
                  setIsDisplay={setIsComparedMapDisplayed}
                  zoom={15}
                />
              )}
            </>
          )}
        </>
      )}
    </StyledTileFormWrapper>
  )
}

export default CustomerOriginTile
