import { Button } from '@dataplace.ai/ui-components/atoms'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    display: flex;
    margin-top: 1rem;
    align-items: center;
    padding: 1rem;
    background: #FAFBFC;
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};

    > div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      > span:first-child {
        color: ${palette.blue};
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
      }

      > span:last-child {
        color: ${palette.dark.normal};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};

        > span {
          display: inline;
        }
      }
    }

    button {
      padding: 5px 15px;
      white-space: nowrap;
    }
  `
})

interface AddMoreDataProps {
  tiles: string[]
  onClick(): void
  loading: boolean
  canFetchDataForPrinting?: boolean
}

const AddMoreData: React.FC<AddMoreDataProps> = ({
  tiles, onClick, loading, canFetchDataForPrinting = true,
}): JSX.Element => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()

  useEffect(() => {
    if (!isPrinting || !canFetchDataForPrinting) return
    onClick()
  }, [isPrinting, canFetchDataForPrinting])

  return (
    <Wrapper className='no-print'>
      <div>
        <span>{t('placeme.tiles.add_data')}</span>
        <span>{tiles.map((tile, i) => <span key={tile}>{`${t(tile)}${i < tiles.length - 1 ? ', ' : ''}`}</span>)}</span>
      </div>
      <Button
        loading={loading}
        loadingText={t('generic.apply')}
        onClick={onClick}
      >
        {`${t('generic.show_data')} (${tiles.length})`}
      </Button>
    </Wrapper>
  )
}

export default AddMoreData
