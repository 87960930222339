import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { SeeMoreWrapper, StyledSpan, SubTitle } from './PotentialWithinChainResult.styles'
import { StyledButton } from '../PotentialWithinChainForm/PotentialWithinChainForm.styles'

interface IAdditionalRanking {
  additionalRankingOpen: boolean
  setAdditionalRankingOpen: React.Dispatch<React.SetStateAction<boolean>>
  tableContent: JSX.Element[][];
}

export const AdditionalRanking: React.FC<IAdditionalRanking> = ({
  additionalRankingOpen,
  setAdditionalRankingOpen,
  tableContent,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {
        additionalRankingOpen
          ? (
            <>
              <SubTitle $borderTop>
                {t('placeme.potential_within_chain.addition_rank.title')}
              </SubTitle>
              <Table
                buildings
                content={tableContent}
                gap='1rem'
                headerTemplate='3fr 2fr 2fr 2fr 2fr 2fr'
                labels={[
                  t('placeme.potential_within_chain.addition_rank.label1'),
                  t('placeme.potential_within_chain.addition_rank.label6'),
                  t('placeme.potential_within_chain.addition_rank.label2'),
                  t('placeme.potential_within_chain.addition_rank.label3'),
                  t('placeme.potential_within_chain.addition_rank.label4'),
                  t('placeme.potential_within_chain.addition_rank.label5'),
                ]}
                rowTemplate='3fr 2fr 2fr 2fr 2fr 2fr'
              />
            </>
          )
          : (
            <SeeMoreWrapper className='no-print'>
              <StyledSpan>{t('placeme.potential_within_chain.addition_rank.span')}</StyledSpan>
              <StyledButton onClick={() => setAdditionalRankingOpen(true)}>
                {t('placeme.potential_within_chain.addition_rank.button_text')}
              </StyledButton>
            </SeeMoreWrapper>
          )
      }
    </>
  )
}
