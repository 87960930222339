import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddMapView, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { IMapLocationProps, LinkWithIconCheckOnMap, MapTile, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { TileSectionIds } from '@dataplace.ai/constants'
import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { snakeToCamel } from '@dataplace.ai/functions/utils'
import ComparedInfo from './ComparedInfo'
import { BuildingsMainType } from './@types/IBuildingsTileData'
import useBuildingTypeMappings from './hooks/useBuildingTypeMappings'

const { v4: uuidv4 } = require('uuid')

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(({ theme: { palette } }) => css`
  border-top: 1px solid ${palette.light.darker};
  padding-top: 2rem;
  margin-bottom: 0;
`)

const MapWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  width: 100%;
  height: 336px;
`

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-right: 10px;
`

const LegendElement = styled.div`
  display: flex;
  align-items: center;
`

const LegendLabel = styled.span(({ theme: { palette } }) => css`
  color: ${palette.black};
  font-size: 9px;
  white-space: nowrap;
`)

const LegendColor = styled.span<{color: string}>(({ color }) => css`
  background-color: ${color};
  width: 10px;
  height:10px;
  border-radius: 2px;
  margin-right: 5px;
`)

interface BuildingsMapProps {
  data: BuildingsMainType
  comparedData?: BuildingsMainType
  tileId: string
  compared?: boolean
  showComparedAddress: boolean
  setMapLocation: React.Dispatch<React.SetStateAction<IMapLocationProps>>
}

const BuildingsMap: React.FC<BuildingsMapProps> = (
  {
    setMapLocation,
    data,
    comparedData,
    tileId,
    compared = false,
    showComparedAddress,
  },
) => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const { value } = useSelector((state: RootState) => state.location)
  const {
    values,
    comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)

  const { buildingTypeMappings } = useBuildingTypeMappings()

  const fetchedData = compared ? comparedData : data

  const layers = useMemo(() => {
    const tile = getTileInfo(tileId, TileSectionIds.SURROUNDINGS, values)

    const rangeCoords = compared
      ? tile?.comparedChosenRange?.geoJSON?.coordinates
      : tile?.chosenRange?.geoJSON?.coordinates

    if (!rangeCoords) return undefined

    const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
      {
        id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
        layer: {
          data: {
            coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
            type: 'Polygon',
            properties: {},
          },
          options:{
            type: 'geojson',
            id: compared ? 'buildings_range_compared' : 'buildings_range',
            style: {
              color: '#0000a2',
              fillColor:'#0000a2',
              weight: 1.5,
              fillOpacity: 0.05,
            },
          },
        },
      },
    ]
    return layers
  }, [values, compared, tileId])

  const legendItems = useMemo(() => fetchedData?.map.legend.map((item) => {
    const camelCaseLabel = item?.label ? snakeToCamel(item?.label) : ''
    return (
      <LegendElement key={uuidv4()}>
        <LegendColor color={item?.color} />
        <LegendLabel>{buildingTypeMappings[camelCaseLabel]?.label}</LegendLabel>
      </LegendElement>
    )
  }), [fetchedData, buildingTypeMappings])

  useEffect(() => {
    if (isPrinting) setMapVisible(true)
  }, [isPrinting])

  return (
    <>
      {showComparedAddress
        ? (
          <ComparedInfo address={compared ? comparedLocation?.location?.address : value?.address} />
        )
        : null}
      {!mapVisible
        ? (
          <AddMapView
            buttonValue={0}
            description='placeme.add_buildings_map.description'
            onChange={() => setMapVisible(true)}
            title='placeme.add_buildings_map.title'
          />
        )
        : (
          <>
            <StyledTitleFormSectionSubTitle>
              <span>{t('placeme.buildings_tile.points_on_map')}</span>
              <LinkWithIconCheckOnMap onClick={() => setIsMapDisplayed(!isMapDisplayed)} />
            </StyledTitleFormSectionSubTitle>
            <MapWrapper>
              {legendItems
                ? (
                  <LegendWrapper>{legendItems}</LegendWrapper>
                )
                : null}
              <MapTile
                dragging
                height='100%'
                layers={layers}
                location={compared ? comparedLocation?.location : value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={compared ? comparedLocation?.location?.address : value?.address}
                setMapLocation={setMapLocation}
                showScaleControl
                styleLayer={{
                  id: fetchedData ? fetchedData.map.id : '',
                  styleId: fetchedData ? fetchedData.map.styleId : '',
                  user: fetchedData ? fetchedData.map.user : '',
                }}
                width='100%'
                zoom={16}
                zoomControl
              />
            </MapWrapper>
            {isMapDisplayed
              ? (
                <MapVisualizationModal
                  isDisplayed={isMapDisplayed}
                  layers={layers}
                  legend={legendItems ? <LegendWrapper>{legendItems}</LegendWrapper> : undefined}
                  location={compared ? comparedLocation?.location : value}
                  mapId={`buildings-tile-${values
                    ?.find(c => c.id === TileSectionIds.SURROUNDINGS)?.tiles
                    ?.find(t => t.id === TileSectionIds.BUILDINGS)?.chosenRange?.catchmentId}${compared ? '_compared' : ''}`}
                  setIsDisplay={setIsMapDisplayed}
                  styleLayer={{
                    id: fetchedData ? fetchedData.map.id : '',
                    styleId: fetchedData ? fetchedData.map.styleId : '',
                    user: fetchedData ? fetchedData.map.user : '',
                  }}
                  zoom={17}
                />
              )
              : null}
          </>
        )}
    </>
  )
}

export default BuildingsMap
