import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { IconButton, NoPrint, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Trash } from 'libs/shared/assets/src/lib/icons/dataplace/trash.svg'
import { ComparedLocationBean, WarningModal } from '@dataplace.ai/ui-components/molecules'
import { RootState } from '../../../../../redux/store'
import { ICannibalizationRow } from '../../organisms/Tiles/Market/Cannibalization/@types/ICannibalizationTileData'

const Wrapper = styled.span(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0.25rem 0;
    background: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};
  `
})

const Header = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

    > div {
      width: 45%;

      > span:last-child {
        margin-top: 0.25rem;
        color: ${palette.dark.normal};
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
      }
    }
  `
})

const Value = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > span:nth-child(1) {
      color: ${palette.black};
      font-size: ${typography.big.pt_24_semibold.fontSize};
      font-weight: ${typography.big.pt_24_semibold.fontWeight};
      line-height: ${typography.big.pt_24_semibold.lineHeight};
      margin-right: 0.25rem;
    }

    > span:nth-child(2) {
      margin-bottom: 0.125rem;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }
  `
})

export interface IChosenLocationCannibalizationTileProps {
  chosenLocalization: ICannibalizationRow
  handleDelete: (localizationId: string) => void
  canBeSave: boolean
  comparedLocation?: ICannibalizationRow
  comparedAddress?: string
}

export const ChosenLocationCannibalizationTile = ({
  chosenLocalization,
  handleDelete,
  canBeSave,
  comparedLocation,
  comparedAddress,
}: IChosenLocationCannibalizationTileProps): JSX.Element => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const fromAddress = value?.address
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)
  return (
    <Wrapper>
      <Header>
        <span>{chosenLocalization?.address}</span>
        { canBeSave && (
          <NoPrint>
            <PopupWithOverlay
              onOpen={() => setOpen(true)}
              open={open}
              trigger={(
                <IconButton>
                  <Trash />
                </IconButton>
              )}
            >
              <WarningModal
                handleAgreement={() => {
                  handleDelete(chosenLocalization?.id)
                  closeModal()
                }}
                handleCancel={closeModal}
                heading={t('placeme.cannibalization.chosen_location.delete_location.heading')}
                redWarning={t('placeme.cannibalization.chosen_location.delete_location.red_warning')}
              />
            </PopupWithOverlay>
          </NoPrint>
        )}
      </Header>
      <Content>
        <div>
          <Value>
            <span>{parseFloat((chosenLocalization?.visitors.fromLocation * 100).toFixed(1))}</span>
            <span>%</span>
            {comparedLocation && (
              <ComparedLocationBean
                comparedAddress={comparedAddress}
                comparedValue={`${parseFloat((comparedLocation?.visitors.fromLocation * 100).toFixed(1))}%`}
                difference={comparedLocation?.visitors.fromLocationDiff}
                mainAddress={value?.address}
                mainValue={`${parseFloat((chosenLocalization?.visitors.fromLocation * 100).toFixed(1))}%`}
                style={{
                  marginLeft: '5px',
                }}
              />
            )}
          </Value>
          <span>
            {t('placeme.chosen_localization_cannibalization_tile.from', {
              fromAddress,
              toAddress: chosenLocalization?.address,
            })}
          </span>
        </div>
        <div>
          <Value>
            <span>{parseFloat((chosenLocalization?.visitors.fromAddress * 100).toFixed(1))}</span>
            <span>%</span>
            {comparedLocation && (
              <ComparedLocationBean
                comparedAddress={comparedAddress}
                comparedValue={`${parseFloat((comparedLocation?.visitors.fromAddress * 100).toFixed(1))}%`}
                difference={comparedLocation?.visitors.fromAddressDiff}
                mainAddress={value?.address}
                mainValue={`${parseFloat((chosenLocalization?.visitors.fromAddress * 100).toFixed(1))}%`}
                style={{
                  marginLeft: '5px',
                }}
              />
            )}
          </Value>
          <span>
            {t('placeme.chosen_localization_cannibalization_tile.to', {
              fromAddress,
              toAddress: chosenLocalization?.address,
            })}
          </span>
        </div>
      </Content>
    </Wrapper>
  )
}

