import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import {
  TileFormRow,
  TileFormSpan,
  LinkWithIconCheckOnMap,
  TileFormSectionTitle,
  TileFormColumn,
  TileFormSection,
  TitleFormSectionSubTitle,
  TileFormWrapper,
  TileFormRowWithData,
  MapTile,
  GaugeChart,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { AddMapView, ComparedLocationBean, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@dataplace.ai/functions/utils'

import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { RootState } from '../../../../../../../redux/store'
import { fetchWorkspaceUsageValue, saveTileData } from '../../../../../slice/analysisSlice'
import { IPeopleTileData } from './@types/IPeopleTileData'

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

export const PeopleTile: React.FC<{
  data: IPeopleTileData, tileId: string}> = ({
  data, tileId,
}) => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')
  const { value } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const authContext = useContext(AuthContext)

  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const handleMapOpen = (isCompared: boolean) => {
    if (isCompared) {
      setIsComparedMapDisplayed(!isComparedMapDisplayed)
    } else {
      setIsMapDisplayed(!isMapDisplayed)
    }
  }

  const handleAddMap = () => {
    setMapVisible(true)
  }

  const getLayers = (compared: boolean) => {
    const tile = values?.find(c => c.id === 'inhabitants_and_visitors')?.tiles?.find(t =>
      t.id === tileId)
    const heatMap = compared ? data?.value?.comparedLocation?.populationHeatmap : data?.value?.populationHeatmap
    if (heatMap) {
      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
        // heat map layer
        {
          id: heatMap?.id,
          layer: {
            ...heatMap,
            options: {
              type: heatMap?.type,
              id: heatMap?.id,
              style: {
                ...heatMap?.style,
                blur: 50,
                radius: mapLocation.zoom * 5,
                minOpacity: 0.0,
                gradient: {
                  0: '#0099FF',
                  0.5: 'yellow',
                  1: '#FF0000',
                },
              },

            },
          },
        },
        // ranger layer
        {
          id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
          layer: {
            data: {
              coordinates: (compared ? tile?.comparedChosenRange?.geoJSON?.coordinates : tile?.chosenRange?.geoJSON?.coordinates) as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: compared ? 'people_compared' : 'people',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        },
      ]
      return layers
    }
    return undefined
  }

  const memoLayers = useMemo(() => getLayers(false), [mapLocation.zoom])
  const memoLayersCompared = useMemo(() => getLayers(true), [comparedMapLocation.zoom])

  useEffect(() => {
    if (token.length && !data?.mapLocation) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (data?.value?.populationHeatmap && mapLocation) {
      dispatch(saveTileData('inhabitants_and_visitors', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  useEffect(() => {
    if (isPrinting) handleAddMap()
  }, [isPrinting])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <TileFormWrapper>
        {value?.country === 'PL' && data.value?.dailyAverage && (
          <>
            {' '}
            <TileFormSection>
              <TileFormSectionTitle>{t('placeme.people_tile.section_title_1')}</TileFormSectionTitle>
              {data?.value?.dailyAverage && (
                <>
                  <TileFormRowWithData>
                    <span>{t('placeme.people_tile.row_with_data_1_span_1')}</span>
                    <span>{`${formatNumber(data?.value?.dailyAverage)}`}</span>
                    {data?.value?.comparedLocation?.dailyAverage && (
                      <ComparedLocationBean
                        comparedAddress={comparedLocation?.location?.address}
                        comparedValue={formatNumber(data?.value?.comparedLocation?.dailyAverage)}
                        difference={data?.value?.comparedLocation?.dailyAverageDiff}
                        mainAddress={value?.address}
                        mainValue={formatNumber(data?.value?.dailyAverage)}
                      />
                    )}
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.people_tile.form_span_1')}
                  </TileFormSpan>
                </>
              )}
            </TileFormSection>
          </>
        )}
        <TileFormSection>
          <TileFormSectionTitle>{t('placeme.people_tile.section_title_2')}</TileFormSectionTitle>
          <TileFormRowWithData>
            <span>{(value?.country === 'PL') ? t('placeme.people_tile.row_with_data_4_span_1') : t('placeme.people_tile.row_with_data_4_span_1.foreign')}</span>
            <span>{`${formatNumber(data?.value?.inhabitants?.registeredResidentsValue)}`}</span>
            {data?.value?.comparedLocation?.inhabitants?.registeredResidentsValue && (
              <ComparedLocationBean
                comparedAddress={comparedLocation?.location?.address}
                comparedValue={formatNumber(data?.value?.comparedLocation?.inhabitants?.registeredResidentsValue)}
                difference={data?.value?.comparedLocation?.inhabitants?.registeredResidentsValueDiff}
                mainAddress={value?.address}
                mainValue={formatNumber(data?.value?.inhabitants?.registeredResidentsValue)}
              />
            )}
          </TileFormRowWithData>
          <TileFormSpan>
            {t('placeme.people_tile.form_span_4')}
          </TileFormSpan>
          {/* <TileFormRowWithData>
            <span>{t('placeme.people_tile.row_with_data_8_span_1')}</span>
            <span>{`${formatNumber(data?.value?.inhabitants?.residentsValue)}`}</span>
          </TileFormRowWithData>
          <TileFormSpan>
            {t('placeme.people_tile.form_span_8')}
          </TileFormSpan> */}
          <TileFormRowWithData>
            <span>{(value?.country === 'PL') ? t('placeme.people_tile.row_with_data_5_span_1') : t('placeme.people_tile.row_with_data_5_span_1.foreign')}</span>
            <span>{`${formatNumber(data?.value?.inhabitants?.householdValue)}`}</span>
            {data?.value?.comparedLocation?.inhabitants?.householdValue && (
              <ComparedLocationBean
                comparedAddress={comparedLocation?.location?.address}
                comparedValue={formatNumber(data?.value?.comparedLocation?.inhabitants?.householdValue)}
                difference={data?.value?.comparedLocation?.inhabitants?.householdValueDiff}
                mainAddress={value?.address}
                mainValue={formatNumber(data?.value?.inhabitants?.householdValue)}
              />
            )}
          </TileFormRowWithData>
          <TileFormRowWithData>
            <span>{(value?.country === 'PL') ? t('placeme.people_tile.row_with_data_6_span_1') : t('placeme.people_tile.row_with_data_6_span_1.foreign')}</span>
            <span>{data?.value?.inhabitants?.averageHousehold}</span>
            {data?.value?.comparedLocation?.inhabitants?.averageHousehold && (
              <ComparedLocationBean
                comparedAddress={comparedLocation?.location?.address}
                comparedValue={formatNumber(data?.value?.comparedLocation?.inhabitants?.averageHousehold)}
                difference={data?.value?.comparedLocation?.inhabitants?.averageHouseholdDiff}
                mainAddress={value?.address}
                mainValue={formatNumber(data?.value?.inhabitants?.averageHousehold)}
              />
            )}
          </TileFormRowWithData>
        </TileFormSection>

        {(data?.value?.inhabitants?.densityValue || data?.value?.populationHeatmap) && (
          <TileFormSection>
            { data?.value?.inhabitants?.densityValue
            && (
              <>
                <TileFormSectionTitle>
                  <span>{t('placeme.people_tile.section_title_3_span_1')}</span>
                </TileFormSectionTitle>
                <TileFormRowWithData>
                  <span>{t('placeme.people_tile.row_with_data_7_span_1')}</span>
                </TileFormRowWithData>
                <TileFormSpan>
                  <strong>{`${parseFloat((data?.value?.inhabitants?.densityValue * 100).toFixed(1))} % `}</strong>
                  {(value?.country === 'PL') ? t('placeme.people_tile_percentages.form_span_3_normal_1') : t('placeme.people_tile_percentages.form_span_3_normal_1.foreign')}
                </TileFormSpan>
                <GaugeChart
                  comparedAddress={comparedLocation?.location?.address}
                  comparedDifference={data?.value?.comparedLocation?.inhabitants?.densityValueDiff}
                  comparedValue={`${parseFloat((data?.value?.comparedLocation?.inhabitants?.densityValue * 100).toFixed(1))}`}
                  height={150}
                  mainAddress={value?.address}
                  mainValue={`${parseFloat((data?.value?.inhabitants?.densityValue * 100).toFixed(1))}`}
                  unit='%'
                  value={data?.value?.inhabitants?.densityValue * 100}
                />
                <TileFormRow width='100%'>
                  <TileFormColumn
                    justify='center'
                    padding='2rem'
                    textAlign='center'
                    width='50%'
                  >
                    <TileFormSpan>
                      {(value?.country === 'PL') ? t('placeme.people_tile.form_span_5') : t('placeme.people_tile.form_span_5.foreign')}
                    </TileFormSpan>
                  </TileFormColumn>
                  <TileFormColumn
                    justify='center'
                    padding='2rem'
                    textAlign='center'
                    width='50%'
                  >
                    <TileFormSpan>
                      {(value?.country === 'PL') ? t('placeme.people_tile.form_span_6') : t('placeme.people_tile.form_span_6.foreign')}
                    </TileFormSpan>
                  </TileFormColumn>
                </TileFormRow>
              </>
            )}
            {
              (mapVisible && data?.value?.populationHeatmap) && (
                <>
                  <TitleFormSectionSubTitle>
                    <span>
                      {t('placeme.people_tile.section_sub_title_2_span_1')}
                      {' '}
                      {data?.value?.comparedLocation ? value?.address : ''}

                    </span>
                    <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
                  </TitleFormSectionSubTitle>
                  <MapWrapper>
                    <MapTile
                      height='100%'
                      layers={memoLayers}
                      location={value}
                      mapId='example-map-data-tile'
                      pinDisplayed
                      popupHeading={t('generic.chosen_location')}
                      popupParagraph={value?.address}
                      setMapLocation={setMapLocation}
                      showScaleControl
                      width='100%'
                      zoom={15}
                      zoomControl
                    />
                  </MapWrapper>
                  {data?.value?.comparedLocation?.populationHeatmap
                  && (
                    <>
                      <TitleFormSectionSubTitle>
                        <span>
                          {t('placeme.people_tile.section_sub_title_2_span_1')}
                          {' '}
                          {comparedLocation?.location?.address}
                        </span>
                        <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                      </TitleFormSectionSubTitle>
                      <MapWrapper>
                        <MapTile
                          height='100%'
                          layers={memoLayersCompared}
                          location={comparedLocation?.location}
                          mapId='example-map-data-tile-compared'
                          pinDisplayed
                          popupHeading={t('generic.chosen_location')}
                          popupParagraph={comparedLocation?.location?.address}
                          setMapLocation={setComparedMapLocation}
                          showScaleControl
                          width='100%'
                          zoom={15}
                          zoomControl
                        />
                      </MapWrapper>
                    </>
                  )}
                </>
              )
            }
            {isMapDisplayed && (
              <MapVisualizationModal
                isDisplayed={isMapDisplayed}
                layers={memoLayers}
                location={value}
                mapId={`people-tile-${values?.find(c => c.id === 'inhabitants_and_visitors')?.tiles?.find(t => t.id === 'people')?.chosenRange?.catchmentId}`}
                setIsDisplay={setIsMapDisplayed}
                setMapLocation={setMapLocation}
                zoom={15}
              />
            )}
            {isComparedMapDisplayed && (
              <MapVisualizationModal
                isDisplayed={isComparedMapDisplayed}
                layers={memoLayersCompared}
                location={comparedLocation?.location}
                mapId={`people-tile-${values?.find(c => c.id === 'inhabitants_and_visitors')?.tiles?.find(t => t.id === 'people')?.chosenRange?.catchmentId}_compared`}
                setIsDisplay={setIsComparedMapDisplayed}
                setMapLocation={setComparedMapLocation}
                zoom={15}
              />
            )}
            {(!mapVisible && (value?.country === 'PL')) && (
              <AddMapView
                buttonValue={1}
                description='placeme.add_people_density.description'
                onChange={handleAddMap}
                title='placeme.add_people_density.title'
              />
            )}
          </TileFormSection>
        )}

      </TileFormWrapper>
    )
}
