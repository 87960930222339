import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RangeChart, TagButton } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as Pin } from '@dataplace.ai/assets/lib/icons/pinIcon.svg'
import { RadarChart } from '@placeme/components/molecules/Bus/RadarChart'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import RankingModal from '@placeme/components/molecules/RankingModal/RankingModal'
import { DemoInfoSection } from '@dataplace.ai/ui-components/molecules/DemoInfoSection/DemoInfoSection'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { Estimation, EstimationLabel, EstimationParagraph, RankingDescription, Section, SubTitle, TagButtonWrapper, Title, Wrapper } from './PotentialWithinChainResult.styles'
import { SimilarAdress } from './SimilarAdress'
import { AdditionalRanking } from './AdditionalRanking'
import { DataType, SettingsListElement } from '../@types/IPotentialWithinChain'
import { useGenerateTableData } from '../hooks/resultData/useGenerateTableData'
import { Settings as SettingsComponent } from '../../../Mcd/Settings'

interface IPotentialWithinChainResult {
  categoryId: string
  data: DataType
  locationType?: ResourceWithId
  settingList: SettingsListElement[]
}

export const PotentialWithinChainResult: React.FC<IPotentialWithinChainResult> = ({
  categoryId,
  data: { value: { potentialModel } },
  locationType,
  settingList,
}) => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const { value: { address } = {} } = useSelector((state: RootState) => state.location)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [additionalRankingOpen, setAdditionalRankingOpen] = useState<boolean>(false)

  const tableContent = useGenerateTableData(potentialModel, locationType)

  useEffect(() => {
    if (isPrinting) setAdditionalRankingOpen(true)
  }, [isPrinting])

  return (
    <Wrapper>
      <SettingsComponent
        marginTop='0'
        selectedSettings={settingList}
      />
      <DemoInfoSection categoryId={categoryId} />
      <Section>
        <Estimation>
          <EstimationParagraph>
            {t('placeme.potential_within_chain.paragraph')}
            <strong>{potentialModel.score}</strong>
          </EstimationParagraph>
          <EstimationLabel>{t('placeme.potential_within_chain.label')}</EstimationLabel>
        </Estimation>

        <SubTitle>{t('placeme.potential_within_chain.section_title_1')}</SubTitle>
        <RankingDescription>{t('placeme.potential_within_chain.line_2')}</RankingDescription>

        <RangeChart
          label='generic.place'
          max={potentialModel.ranking.places.lowest}
          value={potentialModel.ranking.places.current}
        />
        <TagButtonWrapper>
          <TagButton
            className='no-print'
            onClick={() => setModalIsOpen(true)}
          >
            <Pin />
            <p>{t('generic.see_ranking')}</p>
          </TagButton>
        </TagButtonWrapper>
        <RankingModal
          additionalColumn
          currentPosition={potentialModel.ranking.places.current}
          isOpen={modalIsOpen}
          rankingList={potentialModel.ranking.list}
          setIsOpen={setModalIsOpen}
          trimFloat
        />

        <AdditionalRanking
          additionalRankingOpen={additionalRankingOpen}
          setAdditionalRankingOpen={setAdditionalRankingOpen}
          tableContent={tableContent}
        />
      </Section>
      <SimilarAdress similarLocations={potentialModel.similarLocations} />

      <Title $borderTop>{t('placeme.potential_within_chain.bus_chart.title')}</Title>
      <RadarChart
        analysedAddress={address ?? ''}
        similarLocations={potentialModel.similarLocations}
      />
    </Wrapper>
  )
}
