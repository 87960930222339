import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { DemoInfoSection, MapVisualizationModal, WarningModal } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { CoinsButton, PopupWithOverlay, TextButton } from '@dataplace.ai/ui-components/atoms'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { bigBenCategoryId, anwCategoryId } from '@dataplace.ai/constants'
import { IRange } from '@dataplace.ai/types'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import TileErrorBoundary from '@dataplace.ai/features/components/TileErrorBoundary'
import { TileHeader } from '../TileHeader'
import { ITile } from '../../../slice/@types/ITile'
import { RangeSelection } from '../RangeSelection'
import { ReactComponent as Info } from '../../../../../../../../libs/shared/assets/src/lib/icons/largeIcons/info.svg'
import { RootState } from '../../../../../redux/store'
import {
  compareLocationCatchmentAndDataAction,
  deleteTileAction,
  fetchTileDataAction,
  replaceTileDataAction,
  saveNewRangeAction,
} from '../../../slice/analysisSlice'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
// import { TileNotes } from '../TileNotes'
import { tilesWithDoublePropertiesChoose, tilesWithoutRangeChoosing } from './constant/TilesWithDoublePropertiesChoose'
import { getBodyPropertiesForDoublePropertiesTile } from '../AddAnotherLocationModal/functions'
import { tilesWithoutComparedLocation } from '../AddAnotherLocationModal/constants'

const Wrapper = styled.div<{ fullWidth: boolean }>(({
  theme, fullWidth,
}) => {
  const {
    palette, shadows, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    box-shadow: ${shadows.tiny.boxShadow};
    border-left: 0.25rem solid ${palette.product.location.main};
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.main};
    width: ${fullWidth ? '200%' : '74%'};
    height: min-content;

    @media print {
      width: 100%;
      height: 100%;
    }
  `
})

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding: 1.25rem;
  box-sizing: border-box;
`

const InfoWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    margin-bottom: -1.25rem;
    padding: 1.25rem 1.25rem 0 1.25rem;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > svg {
      margin-right: 1rem;
    }

    > span {
      white-space: pre-wrap;
    }
  `
})

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 1rem;
    min-width: 40px;
  }
`

export interface ITileProps {
  tile: ITile
  sectionTile: ISectionTile
  userId: string,
  staticImage?: string
}

export const Tile = ({
  tile,
  sectionTile,
  userId,
  staticImage,
}: ITileProps): JSX.Element => {
  // constants
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const {
    values, creditsAmount, showHints, canBeSave, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, currentSubscriptionData, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)

  const {
    component: Component, canChooseRange, haveToAccept, maxRanges,
  } = tile

  // states
  const [isModalOpen, toggle] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [accepted, setAccepted] = useState(false)
  const [token, setToken] = useState('')

  // functions
  const fetchChosenRange = (category: string, thisTile: string) => (
    values?.find((value) =>
      value.id === category)?.tiles?.find((tile) => tile.id === thisTile)?.chosenRange
  )

  const [chosenRange, setChosenRange] = useState(
    fetchChosenRange(sectionTile.id, tile.id),
  )

  const handleModal = () => {
    toggle(!isModalOpen)
  }

  const handleMaxRanges = () => {
    // case for people for dino's workspace
    if (tile.id.split('-')[0] === 'people' && localStorage?.getItem('currentWorkspaceId') === '6bbd33b3-46e2-4daf-a1b1-ff5d105a6a1f') {
      return {
        car: 30,
        walk: 30,
        bike: 30,
        line: 5000,
        custom: 260000000,
      }
    }
    return maxRanges
  }

  const handleDeleteRange = () => {
    setAccepted(false)
    dispatch(replaceTileDataAction(token, sectionTile.id, tile.id, comparedAnalyseId || analyseId))
    setChosenRange(undefined)
    toggle(!isModalOpen)
  }

  const handleDeleteTile = () => dispatch(deleteTileAction(token, sectionTile.id, tile.id, comparedAnalyseId || analyseId || ''))

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const handleSave = () => {
    if (creditsAmount) {
      setAccepted(true)
    }
  }

  const handleComparedCatchmentAndData = useCallback(async () => {
    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
    try {
      // post project - with compared location - create compared analyse
      if (!comparedAnalyseId) await dispatch(createNewAnalyse(analyseId, comparedLocation?.location))
    }
    finally {
      if (comparedLocation?.location) {
        dispatch(compareLocationCatchmentAndDataAction(
          token,
          tile?.chosenRange?.catchmentId || '',
          tile?.chosenRange as IRange,
          tile.section,
          tile.id,
          currentSubscriptionData?.value?.subscriptionId || '',
          comparedLocation?.location,
          getBodyPropertiesForDoublePropertiesTile(tile.id, []),
        ))
      }
    }
  }, [tile?.chosenRange?.catchmentId])

  const data = useMemo(() => values?.find(c => c.id === sectionTile.id)?.tiles.find(t => t.id === tile.id)?.data,
    [JSON.stringify(values)])

  const tileId = useMemo(() => values?.find(c => c.id === sectionTile.id)?.tiles.find(t => t.id === tile.id)?.id,
    [JSON.stringify(values)])

  const tileWithoutCatchment = ['look_alike_big_ben', 'segmentation_big_ben'].includes(tile.id.split('-')[0])

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (
      chosenRange
      && canBeSave
      && !tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0])
      && token.length
      && !data
      && (sectionTile?.id !== bigBenCategoryId || ['traffic_visualisation_big_ben', 'old_traffic_visualisation_big_ben'].includes(tile.id.split('-')[0]))
      && (tile?.chosenRange?.type === 'custom' || !comparedLocation?.generatedFromNow)
      && sectionTile?.id !== anwCategoryId
    ) {
      dispatch(fetchTileDataAction(
        token,
        sectionTile.id,
        tile.id,
        currentSubscriptionData?.value?.subscriptionId || null,
      ))
    }
  }, [chosenRange, canBeSave, token, currentSubscriptionData?.value?.subscriptionId])

  useEffect(() => {
    setChosenRange(fetchChosenRange(sectionTile.id, tile.id))
  }, [values])

  // handle add catchment id for tiles without customer range choosing
  // you need to add tile id to tilesWithoutRangeChoosing
  // turn canChooseRange false in tilesData
  useEffect(() => {
    if (token && !tile?.chosenRange?.catchmentId && tilesWithoutRangeChoosing.includes(tile?.id.split('-')[0]) && (haveToAccept ? accepted : true)) {
      dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', sectionTile?.id, tile?.id, tile?.defaultRange || {
        id: `${tileId}-250-line`,
        value: 250,
        type: 'line',
      }))
    }
  }, [token, accepted])

  // compared location
  useEffect(() => {
    const typedData = tile?.data?.value as Record<string, unknown>
    if (
      tile?.chosenRange?.catchmentId
      && !typedData?.comparedLocation
      && tile?.chosenRange?.type !== 'custom'
      && !tilesWithoutComparedLocation.includes(tile?.id?.split('-')[0])
      && comparedLocation?.generatedFromNow
      && !typedData
      && !tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0])
    ) {
      handleComparedCatchmentAndData()
    }
  }, [tile?.chosenRange?.catchmentId])

  return (
    <Wrapper
      className='avoid-page-break'
      fullWidth={!showHints}
    >
      <>
        <PopupWithOverlay
          onClose={() => toggle(false)}
          open={isModalOpen}
        >
          <WarningModal
            agreementButtonText={t('generic.delete')}
            cancelButtonText={t('generic.cancel')}
            description={(
              <>
                {t('tile.delete.warning')}
                <br />
                {`${t('tile.delete.save_report')}.`}
              </>
            )}
            handleAgreement={handleDeleteRange}
            handleCancel={handleModal}
            heading={t('tile.delete.heading')}
            redWarning={t('tile.delete.red_warning')}
          />
        </PopupWithOverlay>
        <TileHeader
          dataLoaded={!!data?.value}
          handleMap={handleMapOpen}
          range={chosenRange}
          sectionTile={sectionTile}
          tile={tile}
          toggleChangeRangeModal={handleModal}
        />
        {/* <TileNotes
          dataLoaded={!!data?.value}
          notes={tile.notes}
          sectionTile={sectionTile}
          tile={tile}
          token={token}
        /> */}

        {!chosenRange
          && canChooseRange
          && !data?.value
          && !tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0])
          && (
            <RangeSelection
              category={sectionTile.id}
              clientRanges={sectionTile?.id === bigBenCategoryId}
              isExtraPaid={tile.isExtraPaid}
              maxRanges={handleMaxRanges()}
              tile={tile.id}
              userId={userId}
            />
          )}

        {haveToAccept && !accepted && !tileWithoutCatchment && !data?.value && (
          <>
            {tile.acceptDescription && (
              <InfoWrapper>
                <DescriptionWrapper>
                  <Description>
                    <Info
                      height={40}
                      width={40}
                    />
                    <span>{t(tile.acceptDescription)}</span>
                  </Description>
                  <DemoInfoSection categoryId={tile.section} />
                </DescriptionWrapper>
              </InfoWrapper>
            )}
            <ButtonsWrapper>
              <TextButton onClick={handleDeleteTile}>
                <p>{t('generic.cancel')}</p>
              </TextButton>
              <CoinsButton
                isExtraPaid={tile.isExtraPaid}
                isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
                margin='0 0 0 1.82rem'
                onClick={handleSave}
                text={tile.isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
                tileId={tile?.id ?? ''}
                value={checkComparedCoinsValue(comparedLocation?.generatedFromNow, !!comparedLocation?.location, !tilesWithoutComparedLocation.includes(tile?.id?.split('-')[0]))}
              />
            </ButtonsWrapper>
          </>
        )}

        {haveToAccept && !accepted && tileWithoutCatchment && !data?.value && (
          <>
            {tile.acceptDescription && (
              <InfoWrapper>
                <Info
                  height={40}
                  width={40}
                />
                <span>{t(tile.acceptDescription)}</span>
              </InfoWrapper>
            )}
            <ButtonsWrapper>
              <TextButton onClick={handleDeleteTile}>
                <p>{t('generic.cancel')}</p>
              </TextButton>
              <CoinsButton
                isExtraPaid={tile.isExtraPaid}
                isUnlimited
                margin='0 0 0 1.82rem'
                onClick={handleSave}
                text={tile.isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
                tileId={tile?.id ?? ''}
                value={checkComparedCoinsValue(comparedLocation?.generatedFromNow, !!comparedLocation?.location, !tilesWithoutComparedLocation.includes(tile?.id?.split('-')[0]))}
              />
            </ButtonsWrapper>
          </>
        )}

        {isMapDisplayed && (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            layers={(chosenRange?.geoJSON?.coordinates || tile?.chosenRange?.geoJSON?.coordinates)
              ? [{
                id: chosenRange?.catchmentId || tile?.chosenRange?.catchmentId || '',
                layer: {
                  data: {
                    coordinates: (chosenRange?.geoJSON?.coordinates || tile?.chosenRange?.geoJSON?.coordinates) as IGeojson['data']['coordinates'],
                    type: 'Polygon',
                    properties: {},
                  },
                  options: {
                    type: 'geojson',
                    id: 'some_overlay_id',
                    style: {
                      color: '#0000a2',
                      fillColor: '#0000a2',
                      weight: 0,
                      fillOpacity: 0.3,
                    },
                  },
                },
              }]
              : undefined}
            location={value}
            mapId={`range-map-${chosenRange?.catchmentId || tile?.chosenRange?.catchmentId}`}
            setIsDisplay={setIsMapDisplayed}
          />
        )}

        <TileErrorBoundary name={tileId!}>
          <>
            {/* explanation of display conditions */}
            {/* 1. tiles in which we have to choose a range */}
            {/* 2. tiles where we cannot select a range but do not need to confirm (select something else) */}
            {/* 3. tiles where we have to confirm */}
            {((chosenRange || (!canChooseRange && !haveToAccept) || (haveToAccept && accepted)) || data?.value || tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0]))
              && (typeof Component === 'function'
                ? (
                  <Component
                    accepted={accepted}
                    catchmentId={chosenRange?.catchmentId}
                    data={data}
                    isExtraPaid={tile.isExtraPaid}
                    maxRanges={maxRanges}
                    setAccepted={setAccepted}
                    staticImage={staticImage}
                    tileId={tileId}
                    userId={userId}
                  />
                )
                : (
                  Component
                ))}
          </>
        </TileErrorBoundary>
      </>
    </Wrapper>
  )
}
