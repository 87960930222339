/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import {
  Comparison,
  IMapLocationProps,
  LinkWithIconCheckOnMap,
  MapTile,
  // LinkWithIconCheckOnMap,
  TileFormRowWithData,
  TileFormSpan,
  TileFormWrapper,
  TitleFormSectionSubTitle,
  Tooltip,
  // MapTile,
} from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { formatNumber, getAxios } from '@dataplace.ai/functions/utils'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { AuthContext } from '@dataplace.ai/features'
import { ILocation, IMaxRanges, IRange } from '@dataplace.ai/types'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { AddMapView, ComparedLocationBean, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { BasicLayers, GeojsonLayers, WMSLayers, FeatureCollectionLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { RangeAndCategoryTile } from '../../../../molecules'
import { gravityModelTableData, legend } from './data'
// import { CustomRangeModal } from '../../..'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { compareLocationCatchmentAndDataAction, saveTileData } from '../../../../../slice/analysisSlice'
import { IGravityModelTileData } from './@types/IGravityModelTileData'
import { ComparedLocationHeader } from '../../../../atoms'

const { v4: uuidv4 } = require('uuid')

const StyledTileFormWrapper = styled(TileFormWrapper)<{
  color: 'light' | 'dark'
}>(({
  theme, color,
}) => {
  const { palette } = theme
  return css`
    background-color: ${color === 'dark'
    ? palette.light.main
    : palette.light.white};
  `
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
      margin-bottom: 0;
    `
// const MapWrapper = styled.div(
//   () => css`
//     margin: 1rem 0;
//     width: 100%;
//     height: 300px;
//   `,
// )

const CompetitionSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    flex-direction: column;
    > .competitor {
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
    }

  `
})

const ComparisonSpan = styled.span(
  () => css`
    display: flex !important;
    align-items: center;
    justify-content: flex-end;

    > span {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  `,
)

const DescriptionWrapper = styled.div`
  padding: 15px;
  background: #FFFFFF;
  border: 1px dashed #E1E2E3;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 15px;
`

const DescriptionList = styled.ul(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  color: ${palette.dark.normal};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
  margin: 15px 0;
  margin-left: 12px;
`
})

const SelectedSection = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    background: ${palette.light.main};
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;

    > span > svg {
      cursor: pointer;
    }

    > span {
      display: flex;
    }

    > span > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }

    > div {
      margin: 0.5rem 0;
      display: flex;
      flex-direction: column;
      color: ${palette.dark.normal};
      >p{ 
        color: ${palette.black};
        font-size: ${typography.tiny.pt_12_semibold.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
         line-height: ${typography.tiny.pt_12_semibold.lineHeight};
        >span{ 
          color: ${palette.dark.normal};
          font-size: ${typography.tiny.pt_12_regular.fontSize};
          font-weight: ${typography.tiny.pt_12_regular.fontWeight};
          line-height: ${typography.tiny.pt_12_regular.lineHeight};
        }
      }
    }

    > div > span,
    > div > span > span {
      margin-right: 0.25rem;
    }
  `
})

const MapWrapper = styled.div(
  () => css`
    display: flex;
    margin: 1rem 0;
    width: 100%;
    height: 336px;
  `,
)

const LegendWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-right: 10px;
   >div{
     display: flex;
     align-items: center;
     
     >p{
       color: ${palette.black};
       font-size: 9px;
        white-space: nowrap;
     }
   }
 `
})

const LegendColor = styled.span<{color: string}>(({ color }) => css`
      background-color: ${color};
      width: 10px;
      height:10px;
      border-radius: 2px;
      margin-right: 5px;
    `)

interface IGravityModelTileProps {
  data: IGravityModelTileData
  isExtraPaid?:boolean
  tileId: string
  catchmentId?: string
  userId: string
  maxRanges: IMaxRanges
}

export const GravityModelTile: React.FC<IGravityModelTileProps> = ({
  data, isExtraPaid, tileId, catchmentId, userId, maxRanges,
}) => {
  // VARIABLES /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const dispatch = useAppDispatch()
  const { labels } = gravityModelTableData
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const {
    comparedLocation, values,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, currentSubscriptionData, analyseId,
  } = useSelector((state: RootState) => state.location)
  const authContext = useContext(AuthContext)

  // STATE /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [token, setToken] = useState('')
  const [val, setValue] = useState<ResourceWithId>({
    id: '',
    content: null,
  })

  const [accepted, setAccepted] = useState<boolean>(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [comparedMapVisible, setComparedMapVisible] = useState<boolean>(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })
  // FUNCTIONS /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChange = (value: ResourceWithId) => {
    setValue(value)
  }

  const handleMapOpen = (compared: boolean) => {
    if (compared) { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
    else { setIsMapDisplayed(!isMapDisplayed) }
  }
  const handleAddMap = (compared: boolean) => {
    if (compared) { setComparedMapVisible(true) }
    else { setMapVisible(true) }
  }

  const fetchData = useCallback(async () => {
    if (!val?.id || !catchmentId) { return }
    const body = {
      categoryId: val?.id,
      catchmentId,
    }
    let saveData
    const axiosInstance = await getAxios({
      errCallbackFn: (e) => {
        saveData = {
          loading: false,
          error: e,
          value: null,
        }
      },
    })
    const response = await axiosInstance.post<ITileData>(ENDPOINTS.GRAVITY_MODEL_TILE, body)
    if (response) {
      saveData = {
        loading: false,
        error: '',
        value: response.data,
      }
      if (response.status === 204) {
        window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
        window?.dispatchEvent(new CustomEvent('noDataModal'))
      }
    }
    dispatch(saveTileData('potential', tileId, saveData))
  }, [accepted, catchmentId, val?.id])

  const handleFetchComparedData = useCallback(async () => {
    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
    try {
      // post project - with compared location - create compared analyse
      if (!comparedAnalyseId) await dispatch(createNewAnalyse(analyseId, comparedLocation?.location))
    }
    finally {
      const gravityModelTile = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
        t.id === tileId)
      dispatch(compareLocationCatchmentAndDataAction(
        token,
        catchmentId || '',
        gravityModelTile?.chosenRange as IRange,
        gravityModelTile?.section || '',
        gravityModelTile?.id || '',
        currentSubscriptionData?.value?.subscriptionId || '',
        comparedLocation?.location as ILocation,
        {
          categoryId: val?.id,
        },
      ))
    }
  }, [fetchData, catchmentId, !data?.value, accepted])

  // USE_EFFECT ////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  // compared location or fetch basic data (fetchData)
  useEffect(() => {
    if (catchmentId && !data?.value) {
      const gravityModelTile = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
        t.id === tileId)
      // compared location comparison trigger
      if (comparedLocation?.generatedFromNow && gravityModelTile?.chosenRange?.type !== 'custom') {
        const gravityModelTile = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
          t.id === tileId)
        const catchmentId = gravityModelTile?.chosenRange?.catchmentId
        // compared location
        if (gravityModelTile
              && catchmentId
              && gravityModelTile?.chosenRange?.type !== 'custom'
              && comparedLocation?.location) {
          handleFetchComparedData()
        }
      } else {
        fetchData()
      }
    }
  }, [fetchData, catchmentId, !data?.value, accepted])

  useEffect(() => {
    if (data?.value?.gravityMap && mapLocation) {
      dispatch(saveTileData('potential', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  useEffect(() => {
    if (!isPrinting) return
    handleAddMap(false)
    handleAddMap(true)
  }, [isPrinting])

  // JSX ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getLayers = (compared: boolean) => {
    const items = compared ? data?.value?.comparedLocation : data?.value
    if (items) {
      const features : IFeatureCollection['data']['features'] = []
      const tile = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
        t.id === tileId)
      const rangeCoords = compared
        ? tile?.comparedChosenRange?.geoJSON?.coordinates
        : tile?.chosenRange?.geoJSON?.coordinates
      items?.competition?.forEach(poi => features.push({
        geometry: {
          coordinates: [poi.lng, poi.lat],
          type: 'Point',
        },
        properties: {
          title: `<div style="display: flex; flex-direction: column;"><span>${poi?.name}</span><span>${poi?.address}</span></div>`,
          pinnedItem: {
            iconAnchor:[11, 11],
            class: 'poi-img',
            html: `<img alt='' src='${poi.logo || 'assets/icons/logoPlaceholder.svg'}' style="width:22px; height: 22px;"/>`,
          },
        },
        type: 'Feature',
      }))

      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
        {
          id: 'gravity-points-layer',
          layer: {
            data: {
              features,
              type: 'FeatureCollection',
            },
            options: {
              type: 'geojson',
              id: 'gravity-points',
            },
          },
        }]

      // range layer
      if (rangeCoords) {
        layers.push({
          id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
          layer: {
            data: {
              coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: compared ? 'gravity_range_compared' : 'gravity_range',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        })
      }

      return layers
    }
    return undefined
  }

  const content = (compared: boolean) => {
    const val = (compared && data?.value?.comparedLocation) ? data?.value?.comparedLocation : data?.value
    return val?.competition?.map((val, index) => {
      if (!val) { return [] }
      let count = index
      const getKey = () => {
        count += 1
        return `${val.pointId}_${count}`
      }
      const fixedPercentage = +((val.percent * 100).toFixed(1))
      const rowsArray = [
        <CompetitionSpan key={getKey()}>
          <span className='competitor'>
            {`${val.name}, ${val.address}`}
          </span>
        </CompetitionSpan>,
        <ComparisonSpan key={getKey()}>{formatNumber(val.currentClients)}</ComparisonSpan>,
        <ComparisonSpan key={getKey()}>
          {formatNumber(val.clientsAfterNewSpot)}
          <Tooltip
            content={(
              <span>
                {`${t(`placeme.gravity_model_tile.comparison_tooltip.part_1.${fixedPercentage < 0
                  ? 'negative'
                  : 'positive'}`)} ${Math.abs(fixedPercentage)}% ${t('placeme.gravity_model_tile.comparison_tooltip.part_2')}`}
              </span>
            )}
            maxWidth='25rem'
            position='left bottom'
          >
            <Comparison
              blackArrow
              state={fixedPercentage < 0 ? 'negative' : 'positive'}
              value={`${Math.abs(fixedPercentage)}%`}
              withIcon={false}
            />
          </Tooltip>
        </ComparisonSpan>,
      ]
      if (val?.distanceFromAddress) {
        rowsArray.push(
          <CompetitionSpan
            key={getKey()}
            className='last'
          >
            {val.distanceFromAddress}
          </CompetitionSpan>,
        )
      }
      return rowsArray
    })
  }

  return (
    <>
      <StyledTileFormWrapper color={data?.value ? 'light' : 'dark'}>
        {!accepted && !data?.value && (
          <>
            <RangeAndCategoryTile
              accepted={accepted}
              category='potential'
              categoryValue={val}
              handleCategoryChange={handleChange}
              isExtraPaid={isExtraPaid}
              maxRanges={maxRanges}
              setAccepted={setAccepted}
              tile={tileId}
              tileType='gravity_model'
              userId={userId}
            />
          </>
        )}
        {data?.value && (
          <>
            <SelectedSection>
              <span>
                <span>{t('generic.settings')}</span>
              </span>
              <div>
                <p>
                  {t('placeme.gravity_model.category_title')}
                  :
                  {' '}
                  <span>{t(`placeme.pois_tile.category.${data?.value?.category?.split(' ').join('-')}`)}</span>
                </p>
              </div>

            </SelectedSection>
            <DescriptionWrapper>
              <TileFormSpan>
                {t('placeme.gravity_model_tile.desc_1')}
              </TileFormSpan>
              <DescriptionList>
                <li>
                  {t('placeme.gravity_model_tile.li_1')}
                </li>
                <li>
                  {t('placeme.gravity_model_tile.li_2')}
                </li>
                <li>
                  {t('placeme.gravity_model_tile.li_3')}
                </li>
              </DescriptionList>
              <TileFormSpan>
                {t('placeme.gravity_model_tile.desc_2')}
              </TileFormSpan>
            </DescriptionWrapper>
            <TileFormRowWithData>
              <span>{t('placeme.gravity_model_tile.row_with_data_2_span_1')}</span>
              <span>{formatNumber(data?.value?.probability?.estimatedNumberOfClients)}</span>
              {data?.value?.comparedLocation?.probability?.estimatedNumberOfClients && (
                <ComparedLocationBean
                  comparedAddress={comparedLocation?.location?.address}
                  comparedValue={formatNumber(data?.value?.comparedLocation?.probability?.estimatedNumberOfClients)}
                  difference={data?.value?.comparedLocation?.probability?.estimatedNumberOfClientsDiff}
                  mainAddress={value?.address}
                  mainValue={formatNumber(data?.value?.probability?.estimatedNumberOfClients)}
                />
              )}
            </TileFormRowWithData>
            <TileFormSpan>
              {t('placeme.gravity_model_tile.form_span_1')}
            </TileFormSpan>
            <TileFormRowWithData>
              <span>{t('placeme.gravity_model_tile.row_with_data_3_span_1')}</span>
              <span>{`${+((data?.value?.probability?.choiceProbability * 100).toFixed(1))}%`}</span>
              {data?.value?.comparedLocation?.probability?.choiceProbability && (
                <ComparedLocationBean
                  comparedAddress={comparedLocation?.location?.address}
                  comparedValue={`${+((data?.value?.comparedLocation?.probability?.choiceProbability * 100).toFixed(1))}%`}
                  difference={data?.value?.comparedLocation?.probability?.choiceProbabilityDiff}
                  mainAddress={value?.address}
                  mainValue={`${+((data?.value?.probability?.choiceProbability * 100).toFixed(1))}%`}
                />
              )}
            </TileFormRowWithData>
            <TileFormSpan>
              {t('placeme.gravity_model_tile.form_span_2')}
            </TileFormSpan>
            {data?.value?.comparedLocation
               && (
                 <ComparedLocationHeader
                   style={{
                     marginTop: '1rem',
                   }}
                 >
                   <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                   {' '}
                   <span>{value?.address}</span>
                 </ComparedLocationHeader>
               )}
            <StyledTitleFormSectionSubTitle>
              {t('placeme.gravity_model_tile.section_sub_title_1')}
            </StyledTitleFormSectionSubTitle>

            <Table
              content={content(false)}
              gap='1rem'
              headerTemplate='3fr 1fr 1fr 0.5fr'
              // headerTextAlign='left'
              labels={labels.map((label) => (
                <span key={label}>{t(label)}</span>
              ))}
              rowTemplate='3fr 1fr 1fr 0.5fr'
            />

            {(!mapVisible) && (
              <AddMapView
                buttonValue={0}
                description='placeme.add_buildings_map.description'
                onChange={() => handleAddMap(false)}
                title='placeme.add_gravity_model_map.title'
              />
            )}
            {(mapVisible) && (
              <>
                <StyledTitleFormSectionSubTitle>
                  <span>{t('placeme.gravity_model.map')}</span>
                  <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
                </StyledTitleFormSectionSubTitle>
                <MapWrapper>

                  <LegendWrapper>
                    {legend?.map((item) => (
                      <div key={uuidv4()}>
                        <LegendColor color={item?.color} />
                        <p>{t(item?.label)}</p>
                      </div>
                    ))}
                  </LegendWrapper>

                  <MapTile
                    dragging
                    height='100%'
                    layers={getLayers(false)}
                    location={value}
                    mapId='example-map-data-tile'
                    pinDisplayed
                    popupHeading={`${t('generic.chosen_location')}:`}
                    popupParagraph={value?.address}
                    setMapLocation={setMapLocation}
                    showScaleControl
                    styleLayer={{
                      id: data?.value?.gravityMap?.id,
                      styleId: data?.value?.gravityMap?.styleId,
                      user: data?.value?.gravityMap?.user,
                    }}
                    width='100%'
                    zoom={16}
                    zoomControl
                  />
                </MapWrapper>
              </>
            ) }
            {isMapDisplayed && (
              <MapVisualizationModal
                isDisplayed={isMapDisplayed}
                layers={getLayers(false)}
                legend={(
                  <LegendWrapper>
                    {legend?.map((item) => (
                      <div key={uuidv4()}>
                        <LegendColor color={item?.color} />
                        <p>{t(item?.label)}</p>
                      </div>
                    ))}
                  </LegendWrapper>
                )}
                location={value}
                mapId={`gravity-tile-${values?.find(c => c.id === 'potential')?.tiles?.find(t => t.id === 'gravity_model')?.chosenRange?.catchmentId}`}
                setIsDisplay={setIsMapDisplayed}
                styleLayer={{
                  id: data?.value?.gravityMap?.id,
                  styleId: data?.value?.gravityMap?.styleId,
                  user: data?.value?.gravityMap?.user,
                }}
                zoom={17}
              />
            )}

            {data?.value?.comparedLocation && (
              <>
                <ComparedLocationHeader second>
                  <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                  {' '}
                  <span>{comparedLocation?.location?.address}</span>
                </ComparedLocationHeader>
                <StyledTitleFormSectionSubTitle>
                  {t('placeme.gravity_model_tile.section_sub_title_1')}
                </StyledTitleFormSectionSubTitle>

                <Table
                  content={content(true)}
                  gap='1rem'
                  headerTemplate='3fr 1fr 1fr 0.5fr'
                  // headerTextAlign='left'
                  labels={labels.map((label) => (
                    <span key={label}>{t(label)}</span>
                  ))}
                  rowTemplate='3fr 1fr 1fr 0.5fr'
                />
                {(!comparedMapVisible) && (
                  <AddMapView
                    buttonValue={0}
                    description='placeme.add_buildings_map.description'
                    onChange={() => handleAddMap(true)}
                    title='placeme.add_gravity_model_map.title'
                  />
                )}
                {(comparedMapVisible) && (
                  <>
                    <StyledTitleFormSectionSubTitle>
                      <span>{t('placeme.gravity_model.map')}</span>
                      <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                    </StyledTitleFormSectionSubTitle>
                    <MapWrapper>

                      <LegendWrapper>
                        {legend?.map((item) => (
                          <div key={uuidv4()}>
                            <LegendColor color={item?.color} />
                            <p>{t(item?.label)}</p>
                          </div>
                        ))}
                      </LegendWrapper>

                      <MapTile
                        dragging
                        height='100%'
                        layers={getLayers(true)}
                        location={comparedLocation?.location}
                        mapId='example-map-data-tile'
                        pinDisplayed
                        popupHeading={`${t('generic.chosen_location')}:`}
                        popupParagraph={comparedLocation?.location?.address}
                        setMapLocation={setComparedMapLocation}
                        showScaleControl
                        styleLayer={{
                          id: data?.value?.comparedLocation?.gravityMap?.id,
                          styleId: data?.value?.comparedLocation?.gravityMap?.styleId,
                          user: data?.value?.comparedLocation?.gravityMap?.user,
                        }}
                        width='100%'
                        zoom={14}
                        zoomControl
                      />
                    </MapWrapper>
                  </>
                ) }
                {isComparedMapDisplayed && (
                  <MapVisualizationModal
                    isDisplayed={isComparedMapDisplayed}
                    layers={getLayers(true)}
                    legend={(
                      <LegendWrapper>
                        {legend?.map((item) => (
                          <div key={uuidv4()}>
                            <LegendColor color={item?.color} />
                            <p>{t(item?.label)}</p>
                          </div>
                        ))}
                      </LegendWrapper>
                    )}
                    location={comparedLocation?.location}
                    mapId={`gravity-tile-${values?.find(c => c.id === 'potential')?.tiles?.find(t => t.id === 'gravity_model')?.chosenRange?.catchmentId}`}
                    setIsDisplay={setIsComparedMapDisplayed}
                    styleLayer={{
                      id: data?.value?.comparedLocation?.gravityMap?.id,
                      styleId: data?.value?.comparedLocation?.gravityMap?.styleId,
                      user: data?.value?.comparedLocation?.gravityMap?.user,
                    }}
                    zoom={15}
                  />
                )}
              </>
            )}

          </>
        )}
      </StyledTileFormWrapper>
    </>
  )
}
