/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AddMapView, MapVisualizationModal, NestedSelect, Pagination } from '@dataplace.ai/ui-components/molecules'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  LinkWithIconCheckOnMap,
  MapTile,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { saveTileData, saveTiles } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { BasicLayers, GeojsonLayers, WMSLayers, FeatureCollectionLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { TileSectionIds } from '@dataplace.ai/constants'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { investmentsTableData } from './data'
import { RootState } from '../../../../../../../redux/store'
import { IInvestment, IInvestmentsTileData } from './@types/IInvestmentsTileData'
import { ComparedLocationHeader } from '../../../../atoms'
import { MapWrapper, NestedSelectWrapper, ObjectWrapper, ScrollWrapper, StyledTitleFormSectionSubTitle, TitleWithLegend, Wrapper } from './Investments.styles'
import Expandable from './components/Expandable'
import { getInvestmentIcon } from './utils/functions'
import useHandleTranslationForInvestName from './hooks/useHandleTranslationForInvestName'

const { v4: uuidv4 } = require('uuid')

export const InvestmentsTile: React.FC<{
  data: IInvestmentsTileData,
  tileId: string
}> = ({
  data,
  tileId,
}) => {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const dispatch = useAppDispatch()
  const [val, setValue] = useState<ResourceWithId[]>([])
  // const [sort, setSort] = useState<string>(t('placeme.sort.distance_descending'))
  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const [comparedMapVisible, setComparedMapVisible] = useState<boolean>(false)
  const [categories, setCategories] = useState<ResourceWithId[]>([])
  const { labels } = investmentsTableData
  const { value } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const { handleTranslateForInvestName } = useHandleTranslationForInvestName()

  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [valuesToDisplay, setValuesToDisplay] = useState<{[key: string]: IInvestment[]}>({})
  const [comparedValuesToDisplay, setComparedValuesToDisplay] = useState<{[key: string]: IInvestment[]}>({})
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const catchmentId = useMemo(() => (
    getTileInfo(tileId, TileSectionIds.SURROUNDINGS, values)?.chosenRange?.catchmentId
  ), [tileId, values])

  const handleMapOpen = (compared: boolean) => {
    if (compared) { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
    else { setIsMapDisplayed(!isMapDisplayed) }
  }

  const getLayers = (categories: string[], compared: boolean) => {
    const items = compared ? data?.value?.comparedLocation : data?.value
    if (items) {
      const features : IFeatureCollection['data']['features'] = []
      const tile = values?.find(c => c.id === 'surroundings')?.tiles?.find(t =>
        t.id === tileId)
      const rangeCoords = compared
        ? tile?.comparedChosenRange?.geoJSON?.coordinates
        : tile?.chosenRange?.geoJSON?.coordinates
      items?.investments?.forEach(invest => {
        if (categories.includes(invest?.category)) {
          features.push({
            geometry: {
              coordinates: [invest?.lng, invest?.lat],
              type: 'Point',
            },
            properties: {
              title: `<div style="display: flex; flex-direction: column;"><span>${handleTranslateForInvestName(invest?.name)}</span><span>${invest?.address}</span></div>`,
              pinnedItem: {
                iconAnchor:[11, 11],
                class: 'poi-img',
                html: `<img alt='' src='${getInvestmentIcon(invest?.category)}'/>`,
              },
            },
            type: 'Feature',
          }) } })

      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
        {
          id: 'investment-layer',
          layer: {
            data: {
              features,
              type: 'FeatureCollection',
            },
            options: {
              type: 'geojson',
              id: 'investments',
            },
          },
        }]
      // range layer
      if (rangeCoords) {
        layers.push({
          id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
          layer: {
            data: {
              coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: compared ? 'invest_range_compared' : 'invest_range',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        })
      }

      return layers
    }
    return undefined
  }

  const handleAddMap = (compared: boolean) => {
    if (compared) { setComparedMapVisible(true) }
    else { setMapVisible(true) }
  }

  const getCatchmentId = values?.find(c => c.id === 'surroundings')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

  useEffect(() => {
    if (data?.value) {
      const categoriesObjects : { id: string, content: string}[] = []
      data?.value?.investments?.forEach(investment => {
        const categoryId = investment?.category === 'nan' ? 'other' : investment?.category
        if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
          categoriesObjects.push({
            id: categoryId,
            content: t(`placeme.investments_tile.category.${categoryId.split(' ').join('_')}`),
          })
        }
      })

      if (data?.value?.comparedLocation) {
        data?.value?.comparedLocation?.investments?.forEach(investment => {
          const categoryId = investment?.category === 'nan' ? 'other' : investment?.category
          if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
            categoriesObjects.push({
              id: categoryId,
              content: t(`placeme.investments_tile.category.${categoryId.split(' ').join('_')}`),
            })
          }
        })
      }

      setCategories(categoriesObjects)
      setValue(categoriesObjects)
    }
  }, [data?.value])

  useEffect(() => {
    if (data?.value?.investments?.length) {
      // save categories for pdf
      const toExclude = categories?.filter(cat =>
        !val?.map(item => item?.id).includes(cat?.id)).map(item => item?.id)
      if (catchmentId && toExclude?.length) {
        const pdfTiles = values?.map(cat => {
          if (cat?.id === 'surroundings') {
            return {
              ...cat,
              tiles: cat?.tiles.map(tile => {
                if (tile?.chosenRange?.catchmentId === catchmentId) {
                  return {
                    ...tile,
                    pdfExcluded:toExclude,
                  }
                } return tile
              }),

            }
          }
          return cat
        })
        dispatch(saveTiles(pdfTiles))
      }

      const sortedValues: {[key: string]: IInvestment[]} = {}

      data?.value?.investments.filter(v => val.map(v => v.id)?.includes(v.category)).forEach(invest => {
        if (!sortedValues[invest?.category]) {
          sortedValues[invest?.category] = []
        }
      })

      data?.value?.investments?.filter(v => val.map(v => v.id)?.includes(v.category))
        ?.sort((a, b) => (a.distanceFromPoint < b.distanceFromPoint ? -1 : 1))
        ?.forEach(v => sortedValues[v?.category]?.push(v))

      setValuesToDisplay(sortedValues || [])

      if (data?.value?.comparedLocation) {
        const sortedComparedValues: {[key: string]: IInvestment[]} = {}

        data?.value?.comparedLocation?.investments.filter(v =>
          val.map(v => v.id)?.includes(v.category)).forEach(invest => {
          if (!sortedComparedValues[invest?.category]) {
            sortedComparedValues[invest?.category] = []
          }
        })

        data?.value?.comparedLocation?.investments?.filter(v => val.map(v => v.id)?.includes(v.category))
          ?.sort((a, b) => (a.distanceFromPoint < b.distanceFromPoint ? -1 : 1))
          ?.forEach(v => sortedComparedValues[v.category].push(v))

        setComparedValuesToDisplay(sortedComparedValues || [])
      }
    }
  }, [val])

  useEffect(() => {
    if (data?.value?.investments?.length && mapLocation) {
      dispatch(saveTileData('surroundings', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  useEffect(() => {
    if (!isPrinting) return
    handleAddMap(false)
    handleAddMap(true)
  }, [isPrinting])

  return !data?.value || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <NestedSelectWrapper className='no-print'>
          <span>{t('placeme.investments_tile.investment_categories')}</span>
          <NestedSelect
            name=''
            onChange={setValue}
            options={categories?.map(item => ({
              id: item?.id,
              content: t(`placeme.investments_tile.category.${item?.id.split(' ').join('_')}`),
            }))}
            selected={val?.map(item => ({
              id: item?.id,
              content: t(`placeme.investments_tile.category.${item?.id.split(' ').join('_')}`),
            }))}
            width='50%'
          />
        </NestedSelectWrapper>
        {data?.value?.comparedLocation && (
          <ComparedLocationHeader>
            <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
            {' '}
            <span>{value?.address}</span>
          </ComparedLocationHeader>
        )}
        { Object.entries(valuesToDisplay).map((v) => (
          <ObjectWrapper key={uuidv4()}>
            <TitleWithLegend>
              <img
                alt=''
                src={getInvestmentIcon(v[0])}
              />
              {t(`placeme.investments_tile.category.${v[0].split(' ').join('_')}`)}
            </TitleWithLegend>
            <ScrollWrapper>
              <PerfectScrollbar
                className='scroll'
              >

                <Pagination
                  data={(v[1].map(val => [(val?.name.toLowerCase() === 'nan') ? '-' : handleTranslateForInvestName(val?.name),
                    val?.address, val?.distanceFromPoint * 1000,
                    val?.capacity, val?.dateOfApplication, val?.info, val?.investorName,
                    val?.legalStatus])) as string[][]}
                  dataLimit={5}
                  justifyNavigation='center'
                  renderComponent={(data) => {
                    const newData = data as string[]
                    return (
                      <Table
                        content={newData.map(d => [<span key={uuidv4()}>{d[0]}</span>,
                          <span key={uuidv4()}>{d[1]}</span>,
                          <span key={uuidv4()}>{`${d[2]} m`}</span>])}
                        expandableComponent={newData.map(item => (
                          <Expandable
                            key={uuidv4()}
                            capacity={item[3]}
                            dateOfApplication={item[4]}
                            info={item[5]}
                            investorName={item[6]}
                            // legalStatus={item[7]}
                          />
                        ))}
                        gap='1rem'
                        headerTemplate='5fr 3fr 4fr 1fr'
                        headerTextAlign='left'
                        labels={labels.map((label) => (
                          <span key={label}>{t(label)}</span>
                        ))}
                        rowTemplate='5fr 3fr 4fr 1fr'
                        whiteSpaces='wrap'
                      />
                    ) }}
                />

              </PerfectScrollbar>
            </ScrollWrapper>
          </ObjectWrapper>
        ))}

        {(mapVisible) && (
          <>
            <StyledTitleFormSectionSubTitle>
              <span>{t('placeme.investments_tile.points_on_map')}</span>
              <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
            </StyledTitleFormSectionSubTitle>
            <MapWrapper>
              <MapTile
                height='100%'
                layers={getLayers(Object.keys(valuesToDisplay), false)}
                location={value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={value?.address}
                setMapLocation={setMapLocation}
                showScaleControl
                width='100%'
                zoomControl
              />
            </MapWrapper>
          </>
        )}
        {(!mapVisible) && (
          <AddMapView
            buttonValue={0}
            description='placeme.add_investments_map.description'
            onChange={() => handleAddMap(false)}
            title='placeme.add_investments_map.title'
          />
        )}
        {isMapDisplayed && (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            layers={getLayers(Object.keys(valuesToDisplay), false)}
            location={value}
            mapId={`investment-map-${getCatchmentId}`}
            setIsDisplay={setIsMapDisplayed}
          />
        )}

        {data?.value?.comparedLocation
        && (
          <>
            <ComparedLocationHeader second>
              <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
              {' '}
              <span>{comparedLocation?.location?.address}</span>
            </ComparedLocationHeader>
            { Object.entries(comparedValuesToDisplay).map((v) => (
              <ObjectWrapper key={uuidv4()}>
                <TitleWithLegend>
                  <img
                    alt=''
                    src={getInvestmentIcon(v[0])}
                  />
                  {t(`placeme.investments_tile.category.${v[0].split(' ').join('_')}`)}
                </TitleWithLegend>
                <ScrollWrapper>
                  <PerfectScrollbar
                    className='scroll'
                  >

                    <Pagination
                      data={(v[1].map(val => [(val?.name.toLowerCase() === 'nan') ? '-' : handleTranslateForInvestName(val?.name),
                        val?.address, val?.distanceFromPoint * 1000,
                        val?.capacity, val?.dateOfApplication, val?.info, val?.investorName,
                        val?.legalStatus])) as string[][]}
                      dataLimit={5}
                      justifyNavigation='center'
                      renderComponent={(data) => {
                        const newData = data as string[]
                        return (
                          <Table
                            content={newData.map(d => [<span key={uuidv4()}>{d[0]}</span>,
                              <span key={uuidv4()}>{d[1]}</span>,
                              <span key={uuidv4()}>{`${d[2]} m`}</span>])}
                            expandableComponent={newData.map(item => (
                              <Expandable
                                key={uuidv4()}
                                capacity={item[3]}
                                dateOfApplication={item[4]}
                                info={item[5]}
                                investorName={item[6]}
                                // legalStatus={item[7]}
                              />
                            ))}
                            gap='1rem'
                            headerTemplate='5fr 3fr 4fr 1fr'
                            headerTextAlign='left'
                            labels={labels.map((label) => (
                              <span key={label}>{t(label)}</span>
                            ))}
                            rowTemplate='5fr 3fr 4fr 1fr'
                            whiteSpaces='wrap'
                          />
                        ) }}
                    />

                  </PerfectScrollbar>
                </ScrollWrapper>
              </ObjectWrapper>
            ))}

            {(comparedMapVisible && data?.value?.comparedLocation) && (
              <>
                <StyledTitleFormSectionSubTitle>
                  <span>{t('placeme.investments_tile.points_on_map')}</span>
                  <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                </StyledTitleFormSectionSubTitle>
                <MapWrapper>
                  <MapTile
                    height='100%'
                    layers={getLayers(Object.keys(comparedValuesToDisplay), true)}
                    location={comparedLocation?.location}
                    mapId='example-map-data-tile_compared'
                    pinDisplayed
                    popupHeading={`${t('generic.chosen_location')}:`}
                    popupParagraph={comparedLocation?.location?.address}
                    setMapLocation={setComparedMapLocation}
                    showScaleControl
                    width='100%'
                    zoomControl
                  />
                </MapWrapper>
              </>
            )}
            {(!comparedMapVisible) && (
              <AddMapView
                buttonValue={0}
                description='placeme.add_investments_map.description'
                onChange={() => handleAddMap(true)}
                title='placeme.add_investments_map.title'
              />
            )}
            {isComparedMapDisplayed && (
              <MapVisualizationModal
                isDisplayed={isComparedMapDisplayed}
                layers={getLayers(Object.keys(comparedValuesToDisplay), true)}
                location={comparedLocation?.location}
                mapId={`investment-map-${getCatchmentId}_compared`}
                setIsDisplay={setIsComparedMapDisplayed}
              />
            )}
          </>
        )}

      </Wrapper>
    )
}
